var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "exam-main-index" }, [
    _c(
      "div",
      [_c("el-button", { on: { click: _vm.sssa } }, [_vm._v("下载")])],
      1
    ),
    _c(
      "div",
      { staticStyle: { width: "fit-content" }, attrs: { id: "examcard" } },
      [
        _c(
          "el-form",
          {
            key: "msExamMainForm",
            ref: "msExamMainFormRef",
            staticClass: "zwx-form edit-form",
            staticStyle: { height: "100%" },
            attrs: { model: _vm.msExamMainForm, "label-position": "right" },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center"
                }
              },
              [
                _c("div", { staticClass: "exam-main" }, [
                  _vm.msExamMainForm.scoreMode == 1000
                    ? _c(
                        "div",
                        { staticClass: "exam-main-left" },
                        [
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.msExamMainForm.radioQuestionList
                          )
                            ? _c("base-headline", {
                                staticClass: "no-top",
                                staticStyle: { "padding-right": "2px" },
                                attrs: { title: "单选题" }
                              })
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.msExamMainForm.radioQuestionList
                          )
                            ? _c(
                                "div",
                                { staticClass: "question-body" },
                                [
                                  _vm._l(
                                    _vm.msExamMainForm.radioQuestionList,
                                    function(item, i) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            key: item.rid,
                                            staticClass: "question"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "question-num" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.serialNumber) +
                                                    "、"
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "question-head" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "question-title"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.topicContent
                                                      ) +
                                                        "（" +
                                                        _vm._s(
                                                          _vm.$zwxBase.getNumberByStr(
                                                            item.score
                                                          )
                                                        ) +
                                                        "分）"
                                                    )
                                                  ]
                                                ),
                                                _vm.$zwxBase.verifyIsNotBlank(
                                                  item.picPath
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "question-pic",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openFilePreview(
                                                              item.picPath
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticStyle: {
                                                            width: "100%",
                                                            height: "100%"
                                                          },
                                                          attrs: {
                                                            src:
                                                              _vm.$store.state
                                                                .file +
                                                              item.picPath
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.$zwxBase.verifyIsBlank(
                                                  item.optionLayout
                                                ) || item.optionLayout === 1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "question-options-column"
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio-group",
                                                          {
                                                            staticClass:
                                                              "zwx-radio-group",
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.chooseAnswer(
                                                                  $event,
                                                                  item.serialNumber
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                item.optionValue,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "optionValue",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.optionValue"
                                                            }
                                                          },
                                                          _vm._l(
                                                            item.trainingOptionList,
                                                            function(opt) {
                                                              return _c(
                                                                "el-radio",
                                                                {
                                                                  key: opt.rid,
                                                                  staticClass:
                                                                    "zwx-radio",
                                                                  attrs: {
                                                                    label:
                                                                      opt.rid
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      opt.optionCode
                                                                    ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        opt.optionContent
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                item.optionLayout === 2
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "question-options-default"
                                                      },
                                                      [
                                                        _vm._l(
                                                          item.trainingOptionList,
                                                          function(opt) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "img-body"
                                                                },
                                                                [
                                                                  _vm.$zwxBase.verifyIsNotBlank(
                                                                    opt.picPath
                                                                  )
                                                                    ? _c(
                                                                        "img",
                                                                        {
                                                                          key:
                                                                            opt.rid,
                                                                          staticStyle: {
                                                                            width:
                                                                              "90px",
                                                                            height:
                                                                              "90px",
                                                                            "margin-bottom":
                                                                              "20px",
                                                                            "margin-right":
                                                                              "60px"
                                                                          },
                                                                          attrs: {
                                                                            src:
                                                                              _vm
                                                                                .$store
                                                                                .state
                                                                                .file +
                                                                              opt.picPath
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.openFilePreview(
                                                                                opt.picPath
                                                                              )
                                                                            }
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "el-radio-group",
                                                                    {
                                                                      staticClass:
                                                                        "zwx-radio-group",
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.chooseAnswer(
                                                                            $event,
                                                                            item.serialNumber
                                                                          )
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.optionValue,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "optionValue",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.optionValue"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-radio",
                                                                        {
                                                                          key:
                                                                            opt.rid,
                                                                          staticClass:
                                                                            "zwx-radio",
                                                                          attrs: {
                                                                            label:
                                                                              opt.rid
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              opt.optionCode
                                                                            ) +
                                                                              " " +
                                                                              _vm._s(
                                                                                opt.optionContent
                                                                              )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "question-flag",
                                                on: {
                                                  click: function($event) {
                                                    item.isTag = !item.isTag
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.isTag === true,
                                                      expression:
                                                        "item.isTag === true"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "filled chis-icon-filled-flag-selected",
                                                  staticStyle: {
                                                    color: "#FF910E"
                                                  }
                                                }),
                                                _c("i", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.isTag !== true,
                                                      expression:
                                                        "item.isTag !== true"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "filled chis-icon-filled-flag-selected",
                                                  staticStyle: {
                                                    color: "#D5D5D5"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        ),
                                        i <
                                        _vm.msExamMainForm.radioQuestionList
                                          .length -
                                          1
                                          ? _c("div", {
                                              key: "divider-line-" + item.rid,
                                              staticClass: "divider-line"
                                            })
                                          : _vm._e()
                                      ]
                                    }
                                  )
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.msExamMainForm.checkQuestionList
                          )
                            ? _c("base-headline", {
                                staticStyle: { "padding-right": "2px" },
                                attrs: { title: "多选题" }
                              })
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.msExamMainForm.checkQuestionList
                          )
                            ? _c(
                                "div",
                                { staticClass: "question-body" },
                                [
                                  _vm._l(
                                    _vm.msExamMainForm.checkQuestionList,
                                    function(item, i) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            key: item.rid,
                                            staticClass: "question"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "question-num" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.serialNumber) +
                                                    "、"
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "question-head" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "question-title"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.topicContent
                                                      ) +
                                                        "（" +
                                                        _vm._s(
                                                          _vm.$zwxBase.getNumberByStr(
                                                            item.score
                                                          )
                                                        ) +
                                                        "分）"
                                                    )
                                                  ]
                                                ),
                                                _vm.$zwxBase.verifyIsNotBlank(
                                                  item.picPath
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "question-pic",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openFilePreview(
                                                              item.picPath
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticStyle: {
                                                            width: "100%",
                                                            height: "100%"
                                                          },
                                                          attrs: {
                                                            src:
                                                              _vm.$store.state
                                                                .file +
                                                              item.picPath
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.$zwxBase.verifyIsBlank(
                                                  item.optionLayout
                                                ) || item.optionLayout === 1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "question-options-column"
                                                      },
                                                      [
                                                        _c(
                                                          "el-checkbox-group",
                                                          {
                                                            staticClass:
                                                              "ms-checkbox-group",
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.chooseAnswer(
                                                                  $event,
                                                                  item.serialNumber
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                item.optionValue,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "optionValue",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.optionValue"
                                                            }
                                                          },
                                                          [
                                                            _vm._l(
                                                              item.trainingOptionList,
                                                              function(opt, j) {
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      key:
                                                                        opt.rid +
                                                                        j,
                                                                      staticStyle: {
                                                                        "margin-bottom":
                                                                          "20px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-checkbox",
                                                                        {
                                                                          key:
                                                                            opt.rid,
                                                                          attrs: {
                                                                            label:
                                                                              opt.rid
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              opt.optionCode
                                                                            ) +
                                                                              " " +
                                                                              _vm._s(
                                                                                opt.optionContent
                                                                              )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                item.optionLayout === 2
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "question-options-default"
                                                      },
                                                      [
                                                        _c(
                                                          "el-checkbox-group",
                                                          {
                                                            staticClass:
                                                              "ms-checkbox-group",
                                                            model: {
                                                              value:
                                                                item.optionValue,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "optionValue",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.optionValue"
                                                            }
                                                          },
                                                          [
                                                            _vm._l(
                                                              item.trainingOptionList,
                                                              function(opt, j) {
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      key:
                                                                        opt.rid +
                                                                        j,
                                                                      staticClass:
                                                                        "cross-layout"
                                                                    },
                                                                    [
                                                                      _vm.$zwxBase.verifyIsNotBlank(
                                                                        opt.picPath
                                                                      )
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticStyle: {
                                                                                width:
                                                                                  "90px",
                                                                                height:
                                                                                  "90px",
                                                                                "margin-bottom":
                                                                                  "20px"
                                                                              },
                                                                              attrs: {
                                                                                src:
                                                                                  _vm
                                                                                    .$store
                                                                                    .state
                                                                                    .file +
                                                                                  opt.picPath
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.openFilePreview(
                                                                                    item.picPath
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "el-checkbox",
                                                                        {
                                                                          key:
                                                                            opt.rid,
                                                                          attrs: {
                                                                            label:
                                                                              opt.rid
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              opt.optionCode
                                                                            ) +
                                                                              " " +
                                                                              _vm._s(
                                                                                opt.optionContent
                                                                              )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "question-flag",
                                                on: {
                                                  click: function($event) {
                                                    item.isTag = !item.isTag
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.isTag === true,
                                                      expression:
                                                        "item.isTag === true"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "filled chis-icon-filled-flag-selected",
                                                  staticStyle: {
                                                    color: "#FF910E"
                                                  }
                                                }),
                                                _c("i", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.isTag !== true,
                                                      expression:
                                                        "item.isTag !== true"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "filled chis-icon-filled-flag-selected",
                                                  staticStyle: {
                                                    color: "#D5D5D5"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        ),
                                        i <
                                        _vm.msExamMainForm.checkQuestionList
                                          .length -
                                          1
                                          ? _c("div", {
                                              key: "divider-line-" + item.rid,
                                              staticClass: "divider-line"
                                            })
                                          : _vm._e()
                                      ]
                                    }
                                  )
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.msExamMainForm.judgeQuestionList
                          )
                            ? _c("base-headline", {
                                staticStyle: { "padding-right": "2px" },
                                attrs: { title: "判断题" }
                              })
                            : _vm._e(),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.msExamMainForm.judgeQuestionList
                          )
                            ? _c(
                                "div",
                                { staticClass: "question-body" },
                                [
                                  _vm._l(
                                    _vm.msExamMainForm.judgeQuestionList,
                                    function(item, i) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            key: item.rid,
                                            staticClass: "question"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "question-num" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.serialNumber) +
                                                    "、"
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "question-head" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "question-title"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.topicContent
                                                      ) +
                                                        "（" +
                                                        _vm._s(
                                                          _vm.$zwxBase.getNumberByStr(
                                                            item.score
                                                          )
                                                        ) +
                                                        "分）"
                                                    )
                                                  ]
                                                ),
                                                _vm.$zwxBase.verifyIsNotBlank(
                                                  item.picPath
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "question-pic",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openFilePreview(
                                                              item.picPath
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticStyle: {
                                                            width: "100%",
                                                            height: "100%"
                                                          },
                                                          attrs: {
                                                            src:
                                                              _vm.$store.state
                                                                .file +
                                                              item.picPath
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.$zwxBase.verifyIsBlank(
                                                  item.optionLayout
                                                ) || item.optionLayout === 1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "question-options-column"
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio-group",
                                                          {
                                                            staticClass:
                                                              "zwx-radio-group",
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.chooseAnswer(
                                                                  $event,
                                                                  item.serialNumber
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                item.optionValue,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "optionValue",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.optionValue"
                                                            }
                                                          },
                                                          _vm._l(
                                                            item.trainingOptionList,
                                                            function(opt) {
                                                              return _c(
                                                                "el-radio",
                                                                {
                                                                  key: opt.rid,
                                                                  staticClass:
                                                                    "zwx-radio",
                                                                  attrs: {
                                                                    label:
                                                                      opt.rid
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      opt.optionCode
                                                                    ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        opt.optionContent
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                item.optionLayout === 2
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "question-options-default"
                                                      },
                                                      [
                                                        _vm._l(
                                                          item.trainingOptionList,
                                                          function(opt) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "img-body"
                                                                },
                                                                [
                                                                  _vm.$zwxBase.verifyIsNotBlank(
                                                                    opt.picPath
                                                                  )
                                                                    ? _c(
                                                                        "img",
                                                                        {
                                                                          key:
                                                                            opt.rid,
                                                                          staticStyle: {
                                                                            width:
                                                                              "90px",
                                                                            height:
                                                                              "90px",
                                                                            "margin-bottom":
                                                                              "20px",
                                                                            "margin-right":
                                                                              "60px"
                                                                          },
                                                                          attrs: {
                                                                            src:
                                                                              _vm
                                                                                .$store
                                                                                .state
                                                                                .file +
                                                                              opt.picPath
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.openFilePreview(
                                                                                opt.picPath
                                                                              )
                                                                            }
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "el-radio-group",
                                                                    {
                                                                      staticClass:
                                                                        "zwx-radio-group",
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.chooseAnswer(
                                                                            $event,
                                                                            item.serialNumber
                                                                          )
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.optionValue,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "optionValue",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.optionValue"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-radio",
                                                                        {
                                                                          key:
                                                                            opt.rid,
                                                                          staticClass:
                                                                            "zwx-radio",
                                                                          attrs: {
                                                                            label:
                                                                              opt.rid
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              opt.optionCode
                                                                            ) +
                                                                              " " +
                                                                              _vm._s(
                                                                                opt.optionContent
                                                                              )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "question-flag",
                                                on: {
                                                  click: function($event) {
                                                    item.isTag = !item.isTag
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.isTag === true,
                                                      expression:
                                                        "item.isTag === true"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "filled chis-icon-filled-flag-selected",
                                                  staticStyle: {
                                                    color: "#FF910E"
                                                  }
                                                }),
                                                _c("i", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.isTag !== true,
                                                      expression:
                                                        "item.isTag !== true"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "filled chis-icon-filled-flag-selected",
                                                  staticStyle: {
                                                    color: "#D5D5D5"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        ),
                                        i <
                                        _vm.msExamMainForm.judgeQuestionList
                                          .length -
                                          1
                                          ? _c("div", {
                                              key: "divider-line-" + item.rid,
                                              staticClass: "divider-line"
                                            })
                                          : _vm._e()
                                      ]
                                    }
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "exam-main-left" }, [
                    _vm.$zwxBase.verifyIsNotBlank(
                      _vm.msExamMainForm.trainingTopicList
                    )
                      ? _c(
                          "div",
                          {
                            staticClass: "question-body",
                            staticStyle: { "padding-top": "5px" }
                          },
                          [
                            _vm._l(
                              _vm.msExamMainForm.trainingTopicList,
                              function(item, i) {
                                return [
                                  _c(
                                    "div",
                                    { key: item.rid, staticClass: "question" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "question-num",
                                          attrs: { id: item.numId }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.serialNumber) + "、"
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "question-head" },
                                        [
                                          _c(
                                            "div",
                                            { attrs: { id: item.marId } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "question-title"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.topicContent) +
                                                      "（" +
                                                      _vm._s(
                                                        _vm.$zwxBase.getNumberByStr(
                                                          item.score
                                                        )
                                                      ) +
                                                      "分）"
                                                  )
                                                ]
                                              ),
                                              _vm.$zwxBase.verifyIsNotBlank(
                                                item.picPath
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "question-pic",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openFilePreview(
                                                            item.picPath
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          width: "100%",
                                                          height: "100%"
                                                        },
                                                        attrs: {
                                                          src: item.picPath,
                                                          crossorigin:
                                                            "anonymous"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm.$zwxBase.verifyIsBlank(
                                            item.optionLayout
                                          ) || item.optionLayout === 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "question-options-column"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-checkbox-group",
                                                        {
                                                          staticClass:
                                                            "ms-checkbox-group mspd",
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.chooseAnswer(
                                                                $event,
                                                                item.serialNumber
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              item.optionValue,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "optionValue",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.optionValue"
                                                          }
                                                        },
                                                        [
                                                          _vm._l(
                                                            item.trainingOptionList,
                                                            function(opt, j) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    key:
                                                                      opt.rid +
                                                                      j,
                                                                    staticStyle: {
                                                                      "margin-bottom":
                                                                        "10px"
                                                                    },
                                                                    attrs: {
                                                                      id:
                                                                        opt.marId
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-checkbox",
                                                                      {
                                                                        key:
                                                                          opt.rid,
                                                                        attrs: {
                                                                          label:
                                                                            opt.rid
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            opt.optionCode
                                                                          ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              opt.optionContent
                                                                            )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          item.optionLayout === 2
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "question-options-default",
                                                  attrs: {
                                                    id: "marId" + item.marId2
                                                  }
                                                },
                                                [
                                                  item.topicType == 2
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "el-checkbox-group",
                                                            {
                                                              staticClass:
                                                                "ms-checkbox-group mspd",
                                                              model: {
                                                                value:
                                                                  item.optionValue,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "optionValue",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.optionValue"
                                                              }
                                                            },
                                                            [
                                                              _vm._l(
                                                                item.trainingOptionList,
                                                                function(
                                                                  opt,
                                                                  j
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        key:
                                                                          opt.rid +
                                                                          j,
                                                                        staticClass:
                                                                          "cross-layout"
                                                                      },
                                                                      [
                                                                        _vm.$zwxBase.verifyIsNotBlank(
                                                                          opt.picPath
                                                                        )
                                                                          ? _c(
                                                                              "img",
                                                                              {
                                                                                staticStyle: {
                                                                                  width:
                                                                                    "90px",
                                                                                  height:
                                                                                    "90px",
                                                                                  "margin-bottom":
                                                                                    "10px"
                                                                                },
                                                                                attrs: {
                                                                                  src:
                                                                                    opt.picPath
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.openFilePreview(
                                                                                      item.picPath
                                                                                    )
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "el-checkbox",
                                                                          {
                                                                            key:
                                                                              opt.rid,
                                                                            attrs: {
                                                                              label:
                                                                                opt.rid
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                opt.optionCode
                                                                              ) +
                                                                                " " +
                                                                                _vm._s(
                                                                                  opt.optionContent
                                                                                )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "question-flag",
                                          on: {
                                            click: function($event) {
                                              item.isTag = !item.isTag
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.isTag === true,
                                                expression:
                                                  "item.isTag === true"
                                              }
                                            ],
                                            staticClass:
                                              "filled chis-icon-filled-flag-selected",
                                            staticStyle: { color: "#FF910E" }
                                          }),
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.isTag !== true,
                                                expression:
                                                  "item.isTag !== true"
                                              }
                                            ],
                                            staticClass:
                                              "filled chis-icon-filled-flag-selected",
                                            staticStyle: { color: "#D5D5D5" }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  i <
                                  _vm.msExamMainForm.trainingTopicList.length -
                                    1
                                    ? _c("div", {
                                        key: "divider-line-" + item.rid,
                                        staticClass: "divider-line"
                                      })
                                    : _vm._e()
                                ]
                              }
                            )
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                ])
              ]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }