<template>
    <div>
      <base-dialog class="factor-select-dialog" ref="factorSelectDialogRef" :title="title" @determine="confirmFactorDialog" @cancel="cancelFactorDialog" @close="closeFactorDialog" :determineDisabled="determineDisabled" @currentPage="factorDialogSearchInit" :pagination="true" :currentPage="factorSelectDialog.currentPage" :pageSize="factorSelectDialog.pageSize" :total="factorSelectDialog.total">
        <el-form class="zwx-form edit-form" key="unShowedFactorForm" :model="factorSelectDialog.unShowedFactorForm" label-position="right" @submit.native.prevent>
          <div class="edit-row">
            <el-form-item :label="tableLabel + '：'" label-width="98px">
              <el-input class="zwx-input" style="width:170px !important;" v-model.trim="factorSelectDialog.unShowedFactorForm.hazardsName" placeholder="请输入危害因素名称" clearable></el-input>
            </el-form-item>
            <el-button class="zwx-button zwx-button-icontext-28" @click="factorDialogSearchInit(1)">查询</el-button>
          </div>
        </el-form>
        <div style="padding-bottom: 20px">
          <el-table class="zwx-table" row-key="rid" :data="factorSelectDialog.factorList" highlight-current-row stripe border>
            <el-table-column label="选择" width="80" header-align="center">
              <template slot-scope="scope">
                <div style="text-align: center">
                  <el-checkbox v-model="scope.row.checked0" @change="declareHazardSelect0(scope.row)"></el-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="factor0.hazardsName" :label="tableLabel" header-align="left" align="left" min-width="400"></el-table-column>
            <el-table-column label="选择" width="80" header-align="center">
              <template slot-scope="scope">
                <div style="text-align: center">
                  <el-checkbox v-model="scope.row.checked1" @change="declareHazardSelect1(scope.row)" v-if="$zwxBase.verifyIsNotBlank(scope.row.factor1)"></el-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="factor1.hazardsName" :label="tableLabel" header-align="left" align="left" min-width="400"></el-table-column>
          </el-table>
        </div>
      </base-dialog>
    </div>
  </template>
  
  <script>
  export default {
    name: "HazardSelectDialog",
    props:{
      //已经选中的危害因素，不进行展示
      selectedFactors: {
        type: Array,
        default: () => []
      },
      //弹框标题
      title: {
        type: String,
        default: '添加危害因素'
      },
      tableLabel: {
        type: String,
        default: '危害因素'
      },
      hazardsSort: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        api: this.$store.state.api,
        factorSelectDialog:{
          currentPage: 1,
          pageSize: 18,
          total: 0,
          //不展示的危害因素
          unShowedFactorForm: {
            hazardsName: '',
            selectedFactors: [],
          },
          factorList: [],
        }
      }
    },
    created(){
    },
    mounted(){
  
    },
    watch:{
    },
    computed: {
      determineDisabled() {
        if (this.$zwxBase.verifyIsNotBlank(this.factorSelectDialog.unShowedFactorForm.selectedFactors)) {
          return false
        }
        return true
      },
    },
    methods:{
      /**
       * 自定义危害因素弹框
       * */
      factorDialogShow() {
        this.factorDialogSearchInit(1)
      },
      /**
       * 危害因素查询
       * @param currentPage
       */
      factorDialogSearchInit(currentPage) {
        let data = {
          currentPage: currentPage,
          hazardsName: this.factorSelectDialog.unShowedFactorForm.hazardsName,
          hazardsSort: this.hazardsSort,
          selectedFactorList: this.selectedFactors,
        }
        this.$system.postJson(
            this.api + '/basic/info/getHazardsFactorsByDeclareEditHazard-1',
            data,
            true,
            true,
            data => {
              if (data.type == '00') {
                this.factorSelectDialog.factorList = data.tableList ? data.tableList : []
                let selectedFactors = this.factorSelectDialog.unShowedFactorForm.selectedFactors
                if (this.$zwxBase.verifyIsNotBlank(selectedFactors)) {
                  for (let i in selectedFactors) {
                    for (let j in this.factorSelectDialog.factorList) {
                      if (this.$zwxBase.verifyIsNotBlank(this.factorSelectDialog.factorList[j].factor0) && selectedFactors[i].codeNo === this.factorSelectDialog.factorList[j].factor0.codeNo) {
                        this.factorSelectDialog.factorList[j].checked0 = true
                      }
                      if (this.$zwxBase.verifyIsNotBlank(this.factorSelectDialog.factorList[j].factor1) && selectedFactors[i].codeNo === this.factorSelectDialog.factorList[j].factor1.codeNo) {
                        this.factorSelectDialog.factorList[j].checked1 = true
                      }
                    }
                  }
                }
                this.factorSelectDialog.pageSize = data.pageSize ? data.pageSize : 18
                this.factorSelectDialog.total = data.total
                this.$refs.factorSelectDialogRef.show(true)
              } else {
                this.$system.notify('错误', data.mess, 'error')
              }
            },
            data => {
              this.$system.notify('错误', data.mess, 'error')
            }
        )
      },
      /**
       * 自定义危害因素选择0
       */
      declareHazardSelect0: function (row) {
        if (row.checked0) {
          this.factorSelectDialog.unShowedFactorForm.selectedFactors.push(row.factor0)
        }
        if (!row.checked0) {
          let index = this.getSelectedFactorsIndex(row.factor0)
          this.factorSelectDialog.unShowedFactorForm.selectedFactors.splice(index, 1)
        }
      },
      /**
       * 自定义危害因素选择1
       */
      declareHazardSelect1: function (row) {
        if (row.checked1) {
          this.factorSelectDialog.unShowedFactorForm.selectedFactors.push(row.factor1)
        }
        if (!row.checked1) {
          let index = this.getSelectedFactorsIndex(row.factor1)
          this.factorSelectDialog.unShowedFactorForm.selectedFactors.splice(index, 1)
        }
      },
      /**
       * 获取选择危害因素下标
       */
      getSelectedFactorsIndex: function (factor) {
        if (this.$zwxBase.verifyIsBlank(this.factorSelectDialog.unShowedFactorForm.selectedFactors)) {
          return -1
        }
        let len = this.factorSelectDialog.unShowedFactorForm.selectedFactors.length
        for (let i = 0; i < len; i++) {
          if (factor.codeNo == this.factorSelectDialog.unShowedFactorForm.selectedFactors[i].codeNo) {
            return parseInt(i)
          }
        }
        return -1
      },
      /**
       * 提交
       */
      confirmFactorDialog(){
        const selectedFactor = Object.assign([],this.factorSelectDialog.unShowedFactorForm.selectedFactors)
        this.$emit('submitFactor', selectedFactor)
        this.$refs.factorSelectDialogRef.show(false)
      },
      /**
       * 危害因素选择弹框取消
       * */
      cancelFactorDialog() {
        if (this.$zwxBase.verifyIsNotBlank(this.factorSelectDialog.unShowedFactorForm.selectedFactors)) {
          this.$confirm('数据还未保存，确定取消操作吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
              .then(() => {
                this.$refs.factorSelectDialogRef.show(false)
              })
              .catch(() => { })
        } else {
          this.$refs.factorSelectDialogRef.show(false)
        }
      },
      /**
       * 危害因素选择弹框关闭回调
       * */
      closeFactorDialog() {
        this.factorSelectDialog.title = ''
        this.factorSelectDialog.dialogRoeName = ''
        this.factorSelectDialog.factorList = []
        this.factorSelectDialog.unShowedFactorForm.hazardsName = ''
        this.factorSelectDialog.unShowedFactorForm.selectedFactors = []
      },
    }
  }
  </script>
  
  <style scoped>
  
  </style>