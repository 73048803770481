<template>
  <div class="exam-main-index">
    <div><el-button @click="sssa">下载</el-button></div>
    <div id="examcard" style="width: fit-content;">
      <el-form class="zwx-form edit-form" style="height: 100%;" key="msExamMainForm" ref="msExamMainFormRef" :model="msExamMainForm" label-position="right" @submit.native.prevent>
        <div style="width: 100%;display: flex;justify-content: center;">
          <div class="exam-main">
            <!-- <zwx-scrollbar-y style="height: 100%;" ref="searchScrollbar"> -->
            <div class="exam-main-left" v-if="msExamMainForm.scoreMode == 1000">
              <base-headline title="单选题" class="no-top" style="padding-right: 2px" v-if="$zwxBase.verifyIsNotBlank(msExamMainForm.radioQuestionList)" />
              <div class="question-body" v-if="$zwxBase.verifyIsNotBlank(msExamMainForm.radioQuestionList)">
                <template v-for="(item, i) in msExamMainForm.radioQuestionList">
                  <div class="question" :key="item.rid">
                    <div class="question-num">{{ item.serialNumber }}、</div>
                    <div class="question-head">
                      <div class="question-title">{{ item.topicContent }}（{{ $zwxBase.getNumberByStr(item.score) }}分）</div>
                      <div class="question-pic" v-if="$zwxBase.verifyIsNotBlank(item.picPath)" @click="openFilePreview(item.picPath)">
                        <img :src="$store.state.file + item.picPath" style="width: 100%;height: 100%" />
                      </div>
                      <div class="question-options-column" v-if="$zwxBase.verifyIsBlank(item.optionLayout) || item.optionLayout === 1">
                        <el-radio-group class="zwx-radio-group" v-model="item.optionValue" @change="chooseAnswer($event, item.serialNumber)">
                          <el-radio class="zwx-radio" v-for="opt in item.trainingOptionList" :key="opt.rid" :label="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                        </el-radio-group>
                      </div>
                      <div class="question-options-default" v-if="item.optionLayout === 2">
                        <template v-for="opt in item.trainingOptionList">
                          <div class="img-body">
                            <img :key="opt.rid" style="width: 90px;height: 90px;margin-bottom: 20px;margin-right: 60px;" :src="$store.state.file + opt.picPath" @click="openFilePreview(opt.picPath)" v-if="$zwxBase.verifyIsNotBlank(opt.picPath)" />
                            <el-radio-group class="zwx-radio-group" v-model="item.optionValue" @change="chooseAnswer($event, item.serialNumber)">
                              <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                            </el-radio-group>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="question-flag" @click="item.isTag = !item.isTag">
                      <i class="filled chis-icon-filled-flag-selected" style="color: #FF910E" v-show="item.isTag === true"></i>
                      <i class="filled chis-icon-filled-flag-selected" style="color: #D5D5D5" v-show="item.isTag !== true"></i>
                    </div>
                  </div>
                  <div class="divider-line" :key="'divider-line-' + item.rid" v-if="i < msExamMainForm.radioQuestionList.length - 1"></div>
                </template>
              </div>
              <base-headline title="多选题" style="padding-right: 2px" v-if="$zwxBase.verifyIsNotBlank(msExamMainForm.checkQuestionList)" />
              <div class="question-body" v-if="$zwxBase.verifyIsNotBlank(msExamMainForm.checkQuestionList)">
                <template v-for="(item, i) in msExamMainForm.checkQuestionList">
                  <div class="question" :key="item.rid">
                    <div class="question-num">{{ item.serialNumber }}、</div>
                    <div class="question-head">
                      <div class="question-title">{{ item.topicContent }}（{{ $zwxBase.getNumberByStr(item.score) }}分）</div>
                      <div class="question-pic" v-if="$zwxBase.verifyIsNotBlank(item.picPath)" @click="openFilePreview(item.picPath)">
                        <img :src="$store.state.file + item.picPath" style="width: 100%;height: 100%" />
                      </div>
                      <div class="question-options-column" v-if="$zwxBase.verifyIsBlank(item.optionLayout) || item.optionLayout === 1">
                        <el-checkbox-group class="ms-checkbox-group" v-model="item.optionValue" @change="chooseAnswer($event, item.serialNumber)">
                          <template v-for="(opt, j) in item.trainingOptionList">
                            <div :key="opt.rid + j" style="margin-bottom: 20px;">
                              <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                            </div>
                          </template>
                        </el-checkbox-group>
                      </div>
                      <div class="question-options-default" v-if="item.optionLayout === 2">
                        <el-checkbox-group class="ms-checkbox-group" v-model="item.optionValue">
                          <template v-for="(opt, j) in item.trainingOptionList">
                            <div :key="opt.rid + j" class="cross-layout">
                              <img style="width: 90px;height: 90px;margin-bottom: 20px" :src="$store.state.file + opt.picPath" @click="openFilePreview(item.picPath)" v-if="$zwxBase.verifyIsNotBlank(opt.picPath)" />
                              <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                            </div>
                          </template>
                        </el-checkbox-group>
                      </div>
                    </div>
                    <div class="question-flag" @click="item.isTag = !item.isTag">
                      <i class="filled chis-icon-filled-flag-selected" style="color: #FF910E" v-show="item.isTag === true"></i>
                      <i class="filled chis-icon-filled-flag-selected" style="color: #D5D5D5" v-show="item.isTag !== true"></i>
                    </div>
                  </div>
                  <div :key="'divider-line-' + item.rid" class="divider-line" v-if="i < msExamMainForm.checkQuestionList.length - 1"></div>
                </template>
              </div>
              <base-headline title="判断题" style="padding-right: 2px" v-if="$zwxBase.verifyIsNotBlank(msExamMainForm.judgeQuestionList)" />
              <div class="question-body" v-if="$zwxBase.verifyIsNotBlank(msExamMainForm.judgeQuestionList)">
                <template v-for="(item, i) in msExamMainForm.judgeQuestionList">
                  <div class="question" :key="item.rid">
                    <div class="question-num">{{ item.serialNumber }}、</div>
                    <div class="question-head">
                      <div class="question-title">{{ item.topicContent }}（{{ $zwxBase.getNumberByStr(item.score) }}分）</div>
                      <div class="question-pic" v-if="$zwxBase.verifyIsNotBlank(item.picPath)" @click="openFilePreview(item.picPath)">
                        <img :src="$store.state.file + item.picPath" style="width: 100%;height: 100%" />
                      </div>
                      <div class="question-options-column" v-if="$zwxBase.verifyIsBlank(item.optionLayout) || item.optionLayout === 1">
                        <el-radio-group class="zwx-radio-group" v-model="item.optionValue" @change="chooseAnswer($event, item.serialNumber)">
                          <el-radio class="zwx-radio" v-for="opt in item.trainingOptionList" :key="opt.rid" :label="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                        </el-radio-group>
                      </div>
                      <div class="question-options-default" v-if="item.optionLayout === 2">
                        <template v-for="opt in item.trainingOptionList">
                          <div class="img-body">
                            <img :key="opt.rid" style="width: 90px;height: 90px;margin-bottom: 20px;margin-right: 60px;" :src="$store.state.file + opt.picPath" @click="openFilePreview(opt.picPath)" v-if="$zwxBase.verifyIsNotBlank(opt.picPath)" />
                            <el-radio-group class="zwx-radio-group" v-model="item.optionValue" @change="chooseAnswer($event, item.serialNumber)">
                              <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                            </el-radio-group>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="question-flag" @click="item.isTag = !item.isTag">
                      <i class="filled chis-icon-filled-flag-selected" style="color: #FF910E" v-show="item.isTag === true"></i>
                      <i class="filled chis-icon-filled-flag-selected" style="color: #D5D5D5" v-show="item.isTag !== true"></i>
                    </div>
                  </div>
                  <div :key="'divider-line-' + item.rid" class="divider-line" v-if="i < msExamMainForm.judgeQuestionList.length - 1"></div>
                </template>
              </div>
            </div>
            <div class="exam-main-left">
              <div class="question-body" v-if="$zwxBase.verifyIsNotBlank(msExamMainForm.trainingTopicList)" style="padding-top: 5px">
                <template v-for="(item, i) in msExamMainForm.trainingTopicList">
                  <div class="question" :key="item.rid">
                    <div :id="item.numId" class="question-num">{{ item.serialNumber }}、</div>
                    <div class="question-head">
                      <div :id="item.marId">
                        <div class="question-title">{{ item.topicContent }}（{{ $zwxBase.getNumberByStr(item.score) }}分）</div>
                        <div class="question-pic" v-if="$zwxBase.verifyIsNotBlank(item.picPath)" @click="openFilePreview(item.picPath)">
                          <img :src="item.picPath" crossorigin="anonymous" style="width: 100%;height: 100%" />
                        </div>
                      </div>
                      <div class="question-options-column" v-if="$zwxBase.verifyIsBlank(item.optionLayout) || item.optionLayout === 1">
                        <div>
                          <el-checkbox-group class="ms-checkbox-group mspd" v-model="item.optionValue" @change="chooseAnswer($event, item.serialNumber)">
                            <template v-for="(opt, j) in item.trainingOptionList">
                              <div :key="opt.rid + j" style="margin-bottom: 10px;" :id="opt.marId">
                                <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                              </div>
                            </template>
                          </el-checkbox-group>
                        </div>
                      </div>
                      <div :id="'marId' + item.marId2" class="question-options-default" v-if="item.optionLayout === 2">
                        <div v-if="item.topicType == 2">
                          <el-checkbox-group class="ms-checkbox-group mspd" v-model="item.optionValue">
                            <template v-for="(opt, j) in item.trainingOptionList">
                              <div :key="opt.rid + j" class="cross-layout">
                                <img style="width: 90px;height: 90px;margin-bottom: 10px" :src="opt.picPath" @click="openFilePreview(item.picPath)" v-if="$zwxBase.verifyIsNotBlank(opt.picPath)" />
                                <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                              </div>
                            </template>
                          </el-checkbox-group>
                        </div>
                      </div>
                    </div>
                    <div class="question-flag" @click="item.isTag = !item.isTag">
                      <i class="filled chis-icon-filled-flag-selected" style="color: #FF910E" v-show="item.isTag === true"></i>
                      <i class="filled chis-icon-filled-flag-selected" style="color: #D5D5D5" v-show="item.isTag !== true"></i>
                    </div>
                  </div>
                  <div class="divider-line" :key="'divider-line-' + item.rid" v-if="i < msExamMainForm.trainingTopicList.length - 1"></div>
                </template>
              </div>
            </div>
            <!-- </zwx-scrollbar-y> -->
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
// import ExportSavePdf from '@/assets/js/htmlPdf.js'
export default {
  name: 'ExamMain',
  components: {},
  props: {},
  data() {
    return {
      aa: '',
      api: this.$store.state.api,
      view: true,
      timing: null, //定时器
      submitBtnDisable: false,
      msExamMainForm: {
        scoreMode: '',
        radioQuestionList: [],
        checkQuestionList: [],
        judgeQuestionList: [],
        trainingTopicList: [],
        answerList: [],
      },
      counts: 0,
    }
  },
  created() { },
  mounted() { },
  activated() { },
  computed: {},
  methods: {
    sssa(name) {
      let maxHeight = document.getElementById('examcard').offsetHeight
      for (let i = 1; i < this.counts + 1; i++) {
        let node = document.getElementById('marId' + i)
        let top = node.offsetTop - 40
        let height = node.offsetHeight
        let page = this.getPage(top, maxHeight)
        if (page * 986 < top + height) {
          let numNode = document.getElementById('numId' + i)
          let mgTop = page * 1006 - top + 'px'
          node.style.paddingTop = mgTop
          if (numNode) {
            numNode.style.paddingTop = mgTop
          }
        }
      }
      this.$nextTick(() => {
        this.$ExportSavePdf('examcard', name, this.$system.formatDate(new Date(), 'YYYY-MM-DD'))
      })
    },
    getPage(top, maxHeight) {
      let index = 1
      for (let y = 986; y <= maxHeight; y = index * 986) {
        if (y > top) {
          return index
        }
        index++
      }
      return index
    },
    // 初始化考试数据
    examInit(data) {
      try {
        this.counts = data.counts
        this.msExamMainForm.scoreMode = data.examConfig.scoreMode
        if (data.examConfig.scoreMode == 10 || data.examConfig.scoreMode == 20) {
          this.msExamMainForm.radioQuestionList = data.tdExamTopicDeailPackListSingle
          if (this.$zwxBase.verifyIsNotBlank(this.msExamMainForm.radioQuestionList)) {
            this.msExamMainForm.radioQuestionList.forEach(item => {
              this.$set(item, 'optionValue', '')
            })
          }
          this.msExamMainForm.checkQuestionList = data.tdExamTopicDeailPackListMultiple
          if (this.$zwxBase.verifyIsNotBlank(this.msExamMainForm.checkQuestionList)) {
            this.msExamMainForm.checkQuestionList.forEach(item => {
              this.$set(item, 'optionValue', [])
            })
          }
          this.msExamMainForm.judgeQuestionList = data.tdExamTopicDeailPackListJudgment
          if (this.$zwxBase.verifyIsNotBlank(this.msExamMainForm.judgeQuestionList)) {
            this.msExamMainForm.judgeQuestionList.forEach(item => {
              this.$set(item, 'optionValue', '')
            })
          }
        }
        // 手动选择的题目按维护的顺序显示
        else {
          // if (data.examConfig.scoreMode == 0) {
          this.msExamMainForm.trainingTopicList = data.trainingTopicList
          if (this.$zwxBase.verifyIsNotBlank(this.msExamMainForm.trainingTopicList)) {
            this.msExamMainForm.trainingTopicList.forEach(item => {
              if (item.topicType == 2) {
                this.$set(item, 'optionValue', [])
              } else {
                this.$set(item, 'optionValue', '')
              }
            })
          }
        }
        this.msExamMainForm.answerList = data.topicList
        this.$forceUpdate()
        this.$nextTick(() => {
          this.sssa(data.name)
        })
      } catch (error) {
      }
    },
    chooseAnswer(value, serialNumber) {
      if (this.$zwxBase.verifyIsNotBlank(serialNumber)) {
        for (let i in this.msExamMainForm.answerList) {
          if (this.$zwxBase.verifyIsNotBlank(this.msExamMainForm.answerList[i].serialNumber) && this.msExamMainForm.answerList[i].serialNumber === serialNumber) {
            this.msExamMainForm.answerList[i].optionValue = value
          }
        }
      }
    },
    /**
     * 文件预览
     */
    openFilePreview(filePath) {
      let fileList = [
        {
          fileName: '',
          filePath: filePath,
        },
      ]
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: 0,
          },
        },
        '*'
      )
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000;
}
.mspd {
  padding-left: 5px;
}
.exam-main-index {
  height: 100%;
  background: #f5fafc;
  /deep/ .el-radio__input {
    display: none !important;
  }
  /deep/ .el-checkbox__input {
    display: none !important;
  }
  /deep/ .el-checkbox__label {
    white-space: pre-wrap !important;
    text-indent: -14px !important;
  }
  /deep/ .el-radio {
    width: 100% !important;
  }
  /deep/ .el-radio__label {
    white-space: pre-wrap !important;
    text-indent: -14px !important;
  }
  .exam-main {
    display: flex;
    background: #f5fafc;
    // height: calc(100vh - 85px) !important;

    .exam-main-left {
      width: 892px;
      background: #ffffff;

      .question-body {
        padding: 0 0 22px 32px;
        // padding-right: 35px;
      }

      .question {
        width: 645px;
        display: flex;
        margin-top: 12px;

        .question-num {
          color: #3a485e;
          font-size: 14px;
          line-height: 22px;
          width: 42px;
          text-align: end;
        }

        .question-head {
          display: flex;
          flex-direction: column;
          width: 100%;

          .question-title {
            width: calc(100% - 71px);
            color: #3a485e;
            font-size: 14px;
            line-height: 22px;
            margin-right: 57px;
          }

          .question-pic {
            width: 130px;
            height: 78px;
          }

          /deep/ .el-checkbox {
            color: #3a485e !important;
          }

          /deep/ .zwx-radio {
            color: #3a485e !important;
          }

          /deep/ .el-checkbox__inner {
            border-color: #316cf5 !important;
          }

          .question-options-column {
            margin-top: 12px;
            // padding-left: 3px;
            /deep/ .zwx-radio-group {
              display: flex;
              flex-direction: column;
            }

            /deep/ .zwx-radio {
              margin-bottom: 20px;
            }

            /deep/ .zwx-radio:last-child {
              margin-bottom: 0;
            }
          }

          .question-options-default {
            margin-top: 22px;
            display: flex;

            .img-body {
              display: flex;
              flex-direction: column;
            }

            /deep/ .zwx-radio {
              min-width: 90px;
              margin-right: 60px !important;
            }

            /deep/ .ms-checkbox-group {
              display: flex;
            }

            .cross-layout {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 47px;
              min-width: 41px;
            }
          }
        }

        .question-flag {
          display: none;
          width: 14px;
          height: 20px;
          cursor: pointer;
          margin-right: 24px;
        }
      }

      .divider-line {
        border-bottom: 1px solid #e1e5f4;
        // margin-top: 12px;
        // margin-right: 32px;
        width: 75%;
      }
    }
  }
}
</style>
