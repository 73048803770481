<template>
  <div class="examination_management" style="padding-bottom: 20px;">
    <!-- 条件搜索部分 -->
    <el-form class="zwx-form" key="msSearchForm" ref="msSearchForm" :model="msSearchForm" label-position="right" @submit.native.prevent>
      <el-form-item label-width="70px" label="考试名称：">
        <el-input class="zwx-input" style="width:300px !important" v-model.trim="msSearchForm.examName" placeholder="" @keyup.enter.native="search()" clearable />
      </el-form-item>
      <el-form-item label-width="70px" label="分数分配：">
        <el-checkbox-group class="zwx-checkbox-group" v-model="msSearchForm.scoreModeArr">
          <el-checkbox class="zwx-checkbox" :label="0">手动选择</el-checkbox>
          <el-checkbox class="zwx-checkbox" :label="1">随机抽取</el-checkbox>
          <el-checkbox class="zwx-checkbox" :label="2">规则抽取</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label-width="42px" label="状态：">
        <el-checkbox-group class="zwx-checkbox-group" v-model="msSearchForm.ifEnableArr">
          <el-checkbox class="zwx-checkbox" :label="1">启用</el-checkbox>
          <el-checkbox class="zwx-checkbox" :label="0">停用</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <el-divider class="base-divider" />
    <div style="padding: 12px 16px;">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="add">添加</el-button>
      <div style="clear: both" />
    </div>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" row-key="rid" :data="dataList" border stripe tooltip-effect="light">
      <el-table-column prop="examName" label="考试名称" width="300" header-align="center" align="left" />
      <el-table-column prop="examTime" label="时长(分钟)" width="150" header-align="center" align="center" />
      <el-table-column prop="totalScore" label="总分数" width="90" header-align="center" align="center" />
      <el-table-column prop="qualifiedScore" label="合格分数" width="90" header-align="center" align="center" />
      <el-table-column prop="scoreMode" label="分数分配" width="150" header-align="center" align="center">
        <template slot-scope="scope">
          {{ scope.row.scoreMode == 0 ? '手动选择' : scope.row.scoreMode == 1 ? '随机抽取' : '规则抽取' }}
        </template>
      </el-table-column>
      <el-table-column prop="topicAmount" label="题目总数" width="150" header-align="center" align="center"></el-table-column>
      <el-table-column prop="ifEnable" label="状态" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          <div class="zwx-circle-text zwx-circle-normal" v-if="scope.row.ifEnable">启用</div>
          <div class="zwx-circle-text zwx-circle-finish" v-else>停用</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" :min-width="110" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="edit(scope.row)">
            编辑
          </el-button>
          <el-button class="zwx-button zwx-button-text-26 " type="danger" v-if="scope.row.ifEnable == false" @click="$system.msgbox(scope.row, '提示', '确定要删除吗？', '确定', '取消', deleteExamination)">删除</el-button>
          <el-button class="zwx-button zwx-button-text-26" :type="scope.row.ifEnable ? 'danger' : 'text'" @click="$system.msgbox(scope.row, '提示', scope.row.ifEnable ? '确定要停用吗？' : '确定要启用吗？', '确定', '取消', changeExaminationState)">
            {{ scope.row.ifEnable ? '停用' : '启用' }}
          </el-button>
          <el-button class="zwx-button zwx-button-text-26" v-if="ifDownLoad" type="text" @click="examDownload(scope.row)">
            试卷下载
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination class="normal-pagination" :parentPage="currentPage" :pageSize="pageSize" :total="total" @currentChange="search" />
    <!-- 考卷信息 -->
    <div style="position: absolute;top:-20000px;">
      <!-- <div style="position: absolute;"> -->
      <!-- <exam-record-detail ref="examRecordDetailRef"></exam-record-detail> -->
      <exam-main ref="examMainRef"></exam-main>
    </div>
  </div>
</template>

<script>
// import examRecordDetail from './components/exam-record-detail.vue'
import ExamMain from './components/ExamMain.vue'
export default {
  name: 'ExaminationManagement',
  components: { ExamMain },
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      ifDownLoad: this.$route.meta.ifDownLoad,
      //查询对象
      msSearchForm: {
        examName: null,
        scoreModeArr: [0, 1, 2],
        ifEnableArr: [1],
      },
      //下方数据表格集合
      currentPage: 1,
      pageSize: 18,
      total: 0,
      dataList: [],
    }
  },
  mounted() {
    this.search(1)
  },
  activated() {
    if (this.$route.params.fresh) this.search(1)
  },
  methods: {
    examDownload(row) {
      // this.$router.push({ name: 'sss', params: { uuid: row.uuid, rid: row.rid } })
      // this.$refs.examRecordDetailRef.getExamRecordDetail(row.examName, row.uuid)
      this.getExamConfigDetailInfo(row)
    },
    getExamConfigDetailInfo(row) {
      let data = {
        // rid: this.examMainForm.examId,
        uuid: row.uuid,
      }
      this.$system.get(
        this.api + '/training/platform/getExamConfigDetail-1',
        data,
        true,
        true,
        data => {
          if (data.type == '00') {
            let index = 0
            data.trainingTopicList.map(item => {
              index++
              item.marId = 'marId' + index
              item.numId = 'numId' + index
              if (item.picPath) {
                this.getBase64(item)
              }
              item.trainingOptionList.map(val => {
                if (item.optionLayout === 2 && !item.marId2) {
                  index++
                  item.marId2 = index
                }
                if (val.picPath) {
                  this.getBase64(val)
                }
                if (this.$zwxBase.verifyIsBlank(item.optionLayout) || item.optionLayout === 1) {
                  index++
                  val.marId = 'marId' + index
                }
              })
            })

            data.counts = index
            data.name = row.examName
            this.$refs.examMainRef.examInit(data)
          } else {
            this.ifLoading = false
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.ifLoading = false
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    async getBase64(item) {
      const response = await fetch(this.$store.state.file + item.picPath)
      const blob = await response.blob()
      const reader = new FileReader()
      reader.onloadend = () => {
        // this.imageData = reader.result
        item.picPath = reader.result
      }
      reader.readAsDataURL(blob)
    },
    add() {
      this.$router.push({ name: 'ExaminationManagementEdit', params: { type: 'add' } })
    },
    edit(row) {
      this.$router.push({ name: 'ExaminationManagementEdit', params: { row: row, type: 'edit' } })
    },
    //查询
    search(currentPage) {
      this.$emit('loading', true)
      this.currentPage = currentPage
      let data = {
        examName: this.msSearchForm.examName,
        ifEnableArr: this.msSearchForm.ifEnableArr,
        scoreModeArr: this.msSearchForm.scoreModeArr, //!= null ? this.msSearchForm.scoreModeArr.join(',') : '',
        currentPage: this.currentPage,
      }
      this.$system.postJson(
        this.api + '/training/platform/getAllExamConfigList-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.dataList = data.resultList
            this.total = data.resultCount
            this.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$emit('loading', false)
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    //单体删除
    deleteExamination(row) {
      this.$emit('loading', true)
      let data = { uuid: row.uuid }
      this.$system.post(
        this.api + '/training/platform/deleteExamConfig-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.search(1)
            this.$system.notify('成功', data.mess, 'success')
            this.currentPage = 1
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
            this.$emit('loading', false)
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    //启用/停用
    changeExaminationState(row) {
      this.$emit('loading', true)
      let data = {
        uuid: row.uuid,
        ifEnable: !row.ifEnable,
      }
      this.$system.postJson(
        this.api + '/training/platform/enableOrDisableExamConfig-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.search(1)
            this.$system.notify('成功', data.mess, 'success')
            this.currentPage = 1
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
            this.$emit('loading', false)
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
  },
}
</script>

<style lang="less" scoped></style>

<style lang="less"></style>
